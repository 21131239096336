import {add_error, clear_error} from '../shared/form_errors.js'
import moment from "moment";

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
})
$(document).ready(function () {
    const form = $('form')

    $('.ckeditor').each(function () {
        return CKEDITOR.replace($(this).attr('id'));
    });
    //store original form data
    if (form.length > 0) {
        form.data('serialize', form.serialize());
    }
})

//check if original from data has changed prior to navigating away
$('a').click(function (e) {
    const form = $('form')
    if (form.length > 0 && !form[0]?.id?.includes('_search') && $(this).attr('href') !== '#') {
        if (form.serialize() !== form.data('serialize')) {
            if (!confirm('You have unsaved changes! Do you want to continue and lose your changes?')) {
                e.preventDefault();
            }
        }
    }
});


$(document).on('ajax:complete', function (event) {
    let request = event.detail[0]
    let msg = request.getResponseHeader("X-Message");
    let alert_type = 'alert-success';
    if (request.getResponseHeader("X-Message-Type").indexOf("error") !== -1) {
        alert_type = 'alert-danger';
    }
    if (request.getResponseHeader("X-Message-Type").indexOf("keep") !== 0) {
        if (msg) {
            $("#flash_hook").replaceWith("<div id='flash_hook'> <p>&nbsp;</p> <div class='row'> <div class='span10 offset1'> <div class='alert " + alert_type + "'> <button type='button' class='close' data-dismiss='alert'>&times;</button>" + msg + "</div> </div> </div> </div>");
        }
        if (!msg) {
            return $("#flash_hook").replaceWith("<div id='flash_hook'></div>");
        }
    }
});


//character counter

$('.count-text').keyup(function () {
    const group = $(this).closest('.input-group');
    const counter = group.find('.counter');
    const max_length = parseInt(counter.data("max")) - counter.text.length;
    const cur_count = max_length - $(this).val().length;
    if (cur_count <= 0) {
        counter.css('color', 'red');
    } else {
        counter.css('color', 'lightgray');
    }
    counter.text('Characters left: ' + String(cur_count));

});

$('[id$=color_swatch]').on('change', function () {
    setSwatchColor($(this))
})

function setSwatchColor(e) {
    const color = e.find(":selected").val();
    const group = e.closest('.input-group');
    const colorSwatch = group.find('.color-swatch').first()
    colorSwatch.css("background-color", color);
}

$('input[type=date]').on('blur', function () {
    if ($(this).val() !== '') {
        moment($(this).val(), "YYYY-MM-DD", true).isValid() ? clear_error($(this)) : add_error($(this), 'Invalid Date')
    }
})






